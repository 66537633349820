import {request, handleResponse} from './httpService'
const apiUrl = process.env.REACT_APP_API_URL;

const listar = async () => {
    const service = await request('get',`${apiUrl}/links`)
    console.log(service);
    if (service.data)
    {
        const userData = service.data;
        return {
         result: true,
         message: 'links correcto',
         data: userData,
         errors: []
       }
       
     } else {
            return {
               result: false,
               message: service.message,
               data: '',
               errors: service.errors
             }
          }

}

const create = async link => {
    return await request('post',`${apiUrl}/links/create`, link);
}

const edit = async link => {
    return await request('put',`${apiUrl}/links/update/${link.id}`, link);
}

const del = async link => {
    return await request('delete',`${apiUrl}/links/delete/${link.id}`);
}

export default { 
         listar, 
         create, 
         edit,
          del }