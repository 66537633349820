import { combineReducers } from 'redux'
import authReducer from './authReducer'
import loadingReducer from './loadingReducer'
import loginTabsReducer from './loginTabsReducer'
import stateReducer from './stateReducer'
import sidenavReducer from './sidenavReducer'
import toastReducer from './toastReducer'
import linksReducer from '../../features/links/linkSlice'
const rootReducer = combineReducers({
    auth: authReducer,
    loading: loadingReducer,
    loginTab: loginTabsReducer,
    states: stateReducer,
    sidenav: sidenavReducer,
    toast: toastReducer,
    links: linksReducer
    });

export default rootReducer;
