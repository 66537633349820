import React, { Component } from 'react';
import './Login.css'
import { connect } from 'react-redux'
import { login, clearErrors } from '../store/actions/authActions'
import { setCurrentTab } from '../store/actions/loginTabsActions'
import { Redirect } from 'react-router-dom'
import TextInput from './TextInput'
import PasswordInput from './PasswordInput'
import {
	FormBuilder,
	FieldGroup,
	FieldControl,
	Validators
} from "react-reactive-form";

import linkservice from '../services/linkservice';
import authService from '../services/authService';
import { withStyles } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';

import AccountBoxIcon from '@material-ui/icons/AccountBox';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import TitleIcon from '@material-ui/icons/Title';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

const useStyles = theme => ({
	root: {
		flexGrow: 1,
		height: '100vh',
		padding: 0,
		backgroundImage: `url(../../assets/images/home-banner-bg.png)`,
		backgroundPosition: 'left top',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundColor: '#fff'
	},
	paper: {
		padding: '16px',
		textAlign: 'center',
		// color: theme.palette.text.secondary,
	},
	logo: {
		width: '50%'
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiTextField-root': {
			margin: '16px',
		},
		'& .MuiFormControl-root': {
			margin: '16px',
		},
	},
	buttonContainer: {
		display: 'flex',
		padding: '8px',
		justifyContent: 'space-between'
	}
});

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<Typography
			component="div"
			role="tabpanel"
			hidden={value !== index}
			id={`nav-tabpanel-${index}`}
			aria-labelledby={`nav-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</Typography>
	);
}

function a11yProps(index) {
	return {
		id: `nav-tab-${index}`,
		'aria-controls': `nav-tabpanel-${index}`,
	};
}

function LinkTab(props) {
	return (
		<Tab
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}

class Login extends Component {

	state = {
		currentTab: 0,
		loading: true
	};


	customToastId = '123';
	toastId = null;

	loginForm = FormBuilder.group({
		username: ['', Validators.compose([
			Validators.required,
			Validators.minLength(5)
		])],
		password: ['', Validators.compose([
			Validators.required,
			Validators.minLength(5)
		])]
	});

	handleSubmitLogin = async (e) => {
		e.preventDefault();
		this.props.userLogin(this.loginForm.value);
		}


	handleTabChange = (event, tab) => {
		this.setState({
			currentTab: tab
		});
	}

	render() {
		const { user, classes } = this.props;
		if (user) {
			console.log('Already logged in, redirect to links');
			return <Redirect to='/links' />;
		}
		return (
			<div className="loginbody" alignItems="center">
				<Grid container spacing={3}  alignItems="center" justify="center" style={{minHeight: '100vh'}}>
					<Grid item xs={8}>
						<Paper className={classes.paper} elevation={8}>
							<Tabs
								value={this.state.currentTab}
								onChange={this.handleTabChange}
								variant="fullWidth"
								indicatorColor="primary"
								textColor="primary"
								aria-label="icon label tabs example"
							>
								<LinkTab icon={<AccountBoxIcon />} label="Log In" href="/login" {...a11yProps(0)} />
							</Tabs>
							<TabPanel value={this.state.currentTab} index={0}>
								<Typography variant="h5" gutterBottom>
									Member Login
								</Typography>
								<FieldGroup
									control={this.loginForm}
									strict={false}
									render={({ get, invalid }) => (
										<form className={classes.form}>
											<FieldControl
												strict={false}
												name="username"
												render={TextInput}
												meta={
													{
														label: "User ID",
														icon: <PersonIcon />,
														placeholder: 'Enter your username'
													}
												}
											/>
											<FieldControl
												strict={false}
												name="password"
												render={PasswordInput}
												meta={
													{
														label: "Password",
														icon: <LockIcon />,
														placeholder: 'Enter Your Password'
													}
												}
											/>
											<div className={classes.buttonContainer}>
												<Button disabled={invalid} onClick={this.handleSubmitLogin} variant="contained" color="primary">
													Log In
												</Button>
											</div>
										</form>
									)}
								/>
							</TabPanel>
						</Paper>
					</Grid>
				</Grid>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	const { user, authError, signUpError, signUpMessage } = state.auth;
	return {
		currentTab: state.loginTab.tab,
		user,
		error: authError || signUpError,
		signUpMessage
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		changeTab: (tab) => dispatch(setCurrentTab(tab)),
		userLogin: (credentials) => dispatch(login(credentials)),
		errorsDisplayed: () => dispatch(clearErrors())
	};
}


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(useStyles)(Login));