import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    loadLinks,
    createLink,
    editLink,
    deleteLink,
    selectLinks,
} from './linkSlice';
import { selectLoading } from '../loading/loadingSlice'
import MaterialTable from 'material-table'
import { Link, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Skeleton from '@material-ui/lab/Skeleton';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 500,
        position: 'relative',
        minHeight: 200,
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    }
}));

export default function LinkList() {
    const user = useSelector(state => state.auth.user);
    const classes = useStyles();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadLinks());
    }, []);
    const links = useSelector(selectLinks);
    const loading = useSelector(selectLoading);
    let history = useHistory();
    const [openDialog, setOpenDialog] = useState(false);
    const [dialogAction, setDialogAction] = useState(null);
    const [nombreError, setNombreError] = useState(false);
    const [textoError, setTextoError] = useState(false);
    const [nombre, setNombre] = useState('');
    const [texto, setTexto] = useState('');
    const [link, setLink] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);

    if (!user) {
        history.push("/login");
    }
    const openDialogForCreate = () => {
        setDialogAction('Crear link');
        setNombre('');
        setTexto('');
        setOpenDialog(true);
    }

    const openDialogForUpdate = (link) => {
        setDialogAction('Editar link');
        setLink(link);
        setNombre(link.nombre);
        setTexto(link.texto);
        setOpenDialog(true);
    }

    const selectLinkForDelete = (link) => {
        setLink(link);
        setOpenConfirm(true);
    }

    const handleCloseDialog = (action) => {
        setOpenDialog(false);
        if (action === 'save') {
            if (!nombre) {
                setNombreError(true)
                return;
            }
            if (!texto) {
                setTextoError(true)
                return;
            }
            if (dialogAction === 'Crear link') {
                dispatch(createLink({
                    nombre,
                    texto
                }));
            } else {
                const updatedLink = Object.assign(link, { nombre, texto });
                dispatch(editLink(updatedLink));
            }
        }
    }

    const handleEliminar = () => {
        setOpenConfirm(false);
        dispatch(deleteLink(link))
    }

    const loadingContent = (
        <div>
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={500} />
            <Skeleton variant="text" />
        </div>
    )

    const content = (
        <div style={{ maxWidth: "100%", padding: '8px' }}>
            <MaterialTable
                columns={[
                    { title: "Nombre", field: "nombre" },
                    { title: "Texto", field: "texto" }
                ]}
                data={links && links.map(link => Object.assign({}, link))}
                title="Links Disponibles"
                actions={[
                    {
                        icon: 'edit',
                        tooltip: 'Edit link',
                        onClick: (event, rowData) => openDialogForUpdate(rowData)
                    },
                    {
                        icon: 'delete',
                        tooltip: 'Delete link',
                        onClick: (event, rowData) => selectLinkForDelete(rowData)
                    }
                ]}
            />
            <Fab className={classes.fab} color="primary" aria-label="add" onClick={openDialogForCreate}>
                <AddIcon />
            </Fab>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="form-dialog-title"
                fullWidth={true}
                maxWidth='md'
            >
                <DialogTitle id="form-dialog-title">{dialogAction}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        error={nombreError}
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                        helperText={'Campo requerido'}
                        margin="dense"
                        id="nombre"
                        label="Nombre"
                        type="text"
                        fullWidth
                    />
                    <TextField
                        error={textoError}
                        value={texto}
                        onChange={(e) => setTexto(e.target.value)}
                        helperText={'Campo requerido'}
                        autoFocus
                        margin="dense"
                        id="texto"
                        label="Texto"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseDialog('cancel')} color="secondary">
                        Cancelar
                    </Button>
                    <Button onClick={() => handleCloseDialog('save')} color="primary">
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                open={openConfirm}
            >
                <DialogTitle id="confirmation-dialog-title">Eliminar link</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Esta seguro que desea eliminar este link? Esta cción es irreversible
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setOpenConfirm(false)} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleEliminar} color="primary">
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );

    return loading ? loadingContent : content;
}
