import { request, handleResponse } from './httpService'
const login = async ({username, password}) => {
    const url = `${process.env.REACT_APP_API_URL}/auth/login`
    const response = await request('post', url, {username, password})
    const result = handleResponse(response); 
    const user = {
        displayName: username,
        token: result.data.token
    }
    localStorage.setItem('user', JSON.stringify(user));
    return user;
}

const logout = () => {
    localStorage.removeItem('user');
}

const getUser = () => {
    return localStorage.getItem('user');
}

export {
    login,
    logout,
    getUser
}