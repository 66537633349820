import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
  name: 'error',
  initialState: {
    status: false,
    message: ''
  },
  reducers: {
    set: (state, action) => {
      state.status = action.payload.status;
      state.message = action.payload.message
    }
  },
});

export const { set } = errorSlice.actions;

export const createError = message => dispatch => {
    dispatch(set({status: true, message}))
    setTimeout(() => {
      dispatch(set({status: false, message: ''}))
    }, 6000);
    
};


export const selectError = state => state.error;

export default errorSlice.reducer;
