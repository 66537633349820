import { createSlice } from '@reduxjs/toolkit';
import linkService from '../../services/linkservice';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    status: false,
  },
  reducers: {
    set: (state, action) => {
      state.status = action.payload;
    }
  },
});

export const { set } = loadingSlice.actions;

export const startLoading = () => dispatch => {
    dispatch(set(true))
};

export const stopLoading = () => dispatch => {
    dispatch(set(false))
};

export const selectLoading = state => state.loading.status;

export default loadingSlice.reducer;
