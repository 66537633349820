import { createSlice } from '@reduxjs/toolkit';
import linkservice from '../../services/linkservice';
import {
  startLoading,
  stopLoading
} from '../loading/loadingSlice'
import { createError } from '../error/errorSlice';

export const linkSlice = createSlice({
  name: 'links',
  initialState: {
    list: [],
  },
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    add: (state, action) => {
      const newList = state.list;
      newList.push(action.payload)
      state.list = newList;
    },
    edit: (state, action) => {
      const link = action.payload;
      state.list = state.list.map(l => l.id === link.id ? link : l)
    },
    del: (state, action) => {
      const link = action.payload;
      const index = state.list.findIndex(l => l.id === link.id)
      const newList = state.list;
      newList.splice(index, 1);
      state.list = newList;
    }
  },
});

export const { setList, add, edit, del } = linkSlice.actions;

export const loadLinks = () => dispatch => {
  dispatch(startLoading())
  linkservice.listar()
  .then(result => {
    dispatch(setList(result.data.data))
    dispatch(stopLoading())
  })
  .catch(e => dispatch(stopLoading()))
};

export const createLink = link => dispatch => {
  dispatch(startLoading())
  linkservice.create(link)
  .then(result => {
    if (result.data.statusCode === 200) {
      const id = result.data.data;
      const newLink = Object.assign(link, {id});
      dispatch(add(newLink))
    } else {
      dispatch(createError(result.data.data))
    }
    dispatch(stopLoading())
  })
  .catch(e => dispatch(stopLoading()))
};

export const editLink = link => dispatch => {
  dispatch(startLoading())
  linkservice.edit(link)
  .then(result => {
    if (result.data.statusCode === 200) {
      dispatch(edit(link))
    } else {
      dispatch(createError(result.data.data))
    }
    dispatch(stopLoading())
  })
  .catch(e => dispatch(stopLoading()))
};

export const deleteLink = link => dispatch => {
  dispatch(startLoading())
  linkservice.del(link)
  .then(result => {
    if (result.data.statusCode === 200) {
      dispatch(del(link))
    } else {
      dispatch(createError(result.data.data))
    }
    dispatch(stopLoading())
  })
  .catch(e => dispatch(stopLoading()))
};

export const selectLinks = state => state.links.list;

export default linkSlice.reducer;
