import React, {Component} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import Login from './Login'
import LinkList from '../features/links/LinkList'
import MySideNav from './SideNav'
import TopNav from './TopNav'
import {connect} from 'react-redux'

import './Container.css';


class Container extends Component {
    render() {
        const { authenticated, isLoading } = this.props;
        const authenticatedContent = (
            <div className="authenticated-content">
                <Switch>
                    <Route exact path='/' component={LinkList} />
                    <Route path='/links' component={LinkList} />
                   
                    <Redirect to="/links" />
                </Switch>
            </div>
        );
        const nonAuthContent = (
            <Switch>
                <Route exact path='/' component={Login} />
                <Route path='/login' component={Login} />
                <Redirect to="/login" />
            </Switch>
        );
        const content = !authenticated ?
            <div className={isLoading ? 'layout hidden' : 'layout'}>
                {nonAuthContent}
            </div>
            :
            <div className={isLoading ? 'layout hidden' : 'root'}>
                <div className="content"> 
                    <TopNav />
                    <main className="main-content">          
                        <MySideNav />
                            
                        {authenticatedContent}
                    </main>
                </div>
            </div>
        return content;
    }
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.auth.user ? true : false,
        isLoading: state.loading.isLoading,
    };
}

export default connect(
    mapStateToProps,
    null
)(Container);