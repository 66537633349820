import * as authService from '../../services/authService'
import {handleAxiosError} from '../../services/utilsService'
import {displayError, displayMessage} from '../../services/toastService'


export const login = (credentials) => {
   
    return (dispatch) => {
        dispatch({type: 'LOGIN_REQUEST'});
        authService.login(credentials)
            .then(user => {
                dispatch({
                    type: 'LOGIN_SUCCESS',
                    user
                });
            })
            .catch(error => {
                const errorMessage = handleAxiosError(error)
                dispatch({
                    type: 'LOGIN_ERROR',
                    error: errorMessage
                });
                dispatch(displayError(errorMessage));
            })
    }
}

export const signOut = () => {
    authService.logout();
    return (dispatch) => {
        dispatch({type: 'SIGNOUT_SUCCESS'});
    }
}


export const clearErrors = () => {
    return {
        type: 'AUTH_CLEAR_ERRORS'
    }
}

